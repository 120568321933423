<template>
  <aheader/>
  <!-- <div class="block">
    <div class="slider">
    <section :class="hiroSize">
        <div id="index_top" ref="index_top" class="hero-carousel">
          <div class="item-1">
            <img height="800" width="1000" style="float: bottom;" src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/index%2Fslider1.png?alt=media&token=4f014b7d-ba9e-4153-aeee-b872b5cc207b">
          </div>
          <div class="item-2">
            <img height="800" width="1000" style="float: bottom;" src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/index%2Fslider2.png?alt=media&token=400bd6cd-6e14-4e80-8f53-4405cd82e6d2">
          </div>
          <div class="item-3">
            <img height="800" width="1000" style="float: bottom;" src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/index%2Fslider3.png?alt=media&token=0013a32c-cff4-47e1-b6de-d428b789098e">
          </div>
          <div class="item-4">
            <img height="800" width="1000" style="float: bottom;" src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/index%2Fslider4.png?alt=media&token=f49d85b7-befa-4ba2-854b-4e08e1090fee">
          </div>
          <div class="item-5">
            <img height="800" width="1000" style="float: bottom;" src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/index%2Fslider5.png?alt=media&token=69cec147-668c-4faa-a700-6d7fdefaf7ce">
          </div>
        </div>
        <div class="hero-head"></div>
        <div class="hero-body"></div>
        <div class="hero-foot"></div>
      </section>
    </div>
  </div> -->
  <!-- <div class="swiper">
   <div class="swiper-wrapper">
     <div class="swiper-slide"><img src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/index%2Fslider1.png?alt=media&token=4f014b7d-ba9e-4153-aeee-b872b5cc207b"></div>
     <div class="swiper-slide"><img src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/index%2Fslider2.png?alt=media&token=400bd6cd-6e14-4e80-8f53-4405cd82e6d2"></div>
     <div class="swiper-slide"><img src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/index%2Fslider3.png?alt=media&token=0013a32c-cff4-47e1-b6de-d428b789098e"></div>
     <div class="swiper-slide"><img src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/index%2Fslider4.png?alt=media&token=f49d85b7-befa-4ba2-854b-4e08e1090fee"></div>
     <div class="swiper-slide"><img src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/index%2Fslider5.png?alt=media&token=69cec147-668c-4faa-a700-6d7fdefaf7ce"></div>
   </div>
   <div class="swiper-button-prev"></div>
   <div class="swiper-button-next"></div>
  </div>
 -->

  <!-- Slider main container -->
<div class="swiper pc_ver">
  <!-- Additional required wrapper -->
  <div class="swiper-wrapper">
    <!-- Slides -->
    <!-- <div class="swiper-slide"><img src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/index%2Fslider1.png?alt=media&token=4f014b7d-ba9e-4153-aeee-b872b5cc207b"></div>
    <div class="swiper-slide"><img src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/index%2Fslider2.png?alt=media&token=400bd6cd-6e14-4e80-8f53-4405cd82e6d2"></div>
    <div class="swiper-slide"><img src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/index%2Fslider3.png?alt=media&token=0013a32c-cff4-47e1-b6de-d428b789098e"></div>
    <div class="swiper-slide"><img src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/index%2Fslider4.png?alt=media&token=f49d85b7-befa-4ba2-854b-4e08e1090fee"></div>
    <div class="swiper-slide"><img src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/index%2Fslider5.png?alt=media&token=69cec147-668c-4faa-a700-6d7fdefaf7ce"></div> -->
    <div class="swiper-slide pc"><img src="@/assets/slider1.png"></div>
    <div class="swiper-slide pc"><img src="@/assets/slider2.png"></div>
    <div class="swiper-slide pc"><img src="@/assets/slider3.png"></div>
    <div class="swiper-slide pc"><img src="@/assets/slider4.png"></div>
    <div class="swiper-slide pc"><img src="@/assets/slider5.png"></div>
    <div class="swiper-slide sp"><img src="@/assets/phone1.png"></div>
    <div class="swiper-slide sp"><img src="@/assets/phone2.png"></div>
    <div class="swiper-slide sp"><img src="@/assets/phone3.png"></div>
    <div class="swiper-slide sp"><img src="@/assets/phone4.png"></div>
    <div class="swiper-slide sp"><img src="@/assets/phone5.png"></div>
  </div>
  <!-- If we need pagination -->
  <div class="swiper-pagination"></div>

  <!-- If we need navigation buttons -->
  <!-- <div class="swiper-button-prev"></div>
  <div class="swiper-button-next"></div> -->

  <!-- If we need scrollbar -->
  <!-- <div class="swiper-scrollbar"></div> -->
</div>



<!-- <div class="swiper ph_ver">
  <div class="swiper-wrapper">
    <div class="swiper-slide"><img src="@/assets/phone1.png"></div>
    <div class="swiper-slide"><img src="@/assets/phone2.png"></div>
    <div class="swiper-slide"><img src="@/assets/phone3.png"></div>
    <div class="swiper-slide"><img src="@/assets/phone4.png"></div>
    <div class="swiper-slide"><img src="@/assets/phone5.png"></div>
  </div>
</div> -->



  <div class="block">
    <div class="has-background-primary-light" style="padding-bottom:30px;">
      <div class="container is-fluid">
      <div class="section">
        <p class="title">
          布団選びの大切さ
        </p>
      </div>
      <div class="content is-normal">
        <div class="block" v-for="item, index in itemList" :key="index">
          <div class="columns">
            <div class="column is-8 is-offset-2">
              <h2 style="margin-bottom:30px;"><u>{{item.name}}</u></h2>
              <div class="columns">
                <div style="margin:auto;">
                  <img height="400" width="600" style="margin-bottom:10px" :src="item.icon">
                </div>
              </div>
              <div class="column is-narrow is-centered has-text-left">
                <h4 style="line-height : 220%">{{item.kind}}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
  <section class="hero is-white is-fullheight">
  <div class="hero-body">
    <div class="">
      <div class="content is-medium">
        <h2>おぐら屋のサービス</h2>
        <div class="content is-medium">
            <div class="column is-8 is-offset-2" style="text-align: left;">１日のうち1/3〜1/4を占める睡眠。日中のだるさや肩・腰の痛みは睡眠の環境づくりで大きく変わると言われています。自分に合った寝具を選ぶことは、生活の充実に大きく貢献するのです。<br/>おぐら屋では、お客様の要望に沿った寝具を提供することができるサービスがございます。</div>
          </div>
        <div class="block">
          <div class="columns">
            <div class="column">
              <div class="notification is-danger is-light is-size-3" style="border-radius:50px">
                オーダーメイド
                <p class="is-size-5">｜</p>
                <p class="is-size-5" style="text-align: left;">お客様の眠りやお布団に関するお悩み事をおうかがいしながら、お客様専用の特別なお布団を丁寧にご準備させていただきます。</p>
                <div class="bulma-clearfix-mixin">
                  <!-- <img height="209" width="300" style="float: top;" src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/index%2Findex_ordermade.png?alt=media&token=70830d1f-470a-4199-a02d-f4f6de008376"> -->
                  <img height="209" width="300" style="float: top;" src="@/assets/index_ordermade.png">
                </div>
                <button class="button is-danger is-outlined" @click="pushPage('tailor_made')">詳しくは<strong>こちら</strong></button>
              </div>
            </div>
            <div class="column">
              <div class="notification is-info is-light is-size-3" style="border-radius:50px">
                定期点検
                <p class="is-size-5">｜</p>
                <p class="is-size-5" style="text-align: left;">ご購入頂いたお布団の点検を行い、常に高い品質のお布団をご利用いただけます。長く良く使っていただけるように点検は欠かせません。</p>
                <div class="bulma-clearfix-mixin">
                  <!-- <img height="209" width="300" style="float: top;" src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/maintenance%2FTenken_top.png?alt=media&token=ede1af51-8a12-4086-af31-41678350c9e1"> -->
                  <img height="209" width="300" style="float: top;" src="@/assets/Tenken_top.png">
                </div>
                <button class="button is-info is-outlined" @click="pushPage('maintenance')">詳しくは<strong>こちら</strong></button>
              </div>
            </div>
          </div>
        </div>
        <div class="block" style="text-align: left;">
          <div class="column is-8 is-offset-2">おぐら屋にはお客様の快適な眠りをサポートするためにオーダーメイドと定期点検を行っています。寝具専門店としての知識と経験豊富なスタッフが、上質な睡眠をお届けするお手伝いをいたします。お気軽にご相談ください。</div>
        </div>
      </div>
    </div>
  </div>
  </section>
  <div class="block">
    <button class="button has-background-danger-dark has-text-white-ter" @click="pushPage('application')">ご予約はこちらから</button>
  </div>

  <afooter/>



</template>
<style>
.pc {display: block !important;}
.sp {display: none !important;}
@media only screen and (max-width: 750px) {
  .pc {display: none !important;}
  .sp {display: block !important;}
}
/* @media only screen and (min-width: 750px) {
  .pc {display: block !important;}
  .sp {display: none !important;}
} */
@media only screen and (max-width: 750px) {
  img { max-width: 100%; }
}
.target{
  width:70%;
  text-align: left;
}
.swiper{
   width: 68vw;
}
.swiper-pagination-bullet {
  width: 12px; /* 幅 */
  height: 12px; /* 高さ */
  background: #9acd32; /* 色：緑 */
  opacity: 0.5; /* 半透明（デフォルトでは0.2） */
}

.swiper-pagination-bullet-active {
  width: 20px; /* 幅 */
  height: 20px; /* 高さ */
  background: #ffd700; /* 色：黄色 */
  opacity: 1; /* 不透明 */
}

</style>
<script>
  export default {
    data() {
      return {
        itemList: [
          // {name: "腰痛・肩こりに", icon: "https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/index%2FHome_koshi.png?alt=media&token=6951608d-157f-4fcd-821c-40d91484a11a", kind: "立ち仕事や事務仕事など、１日中同じような姿勢でいることは腰痛などの原因となります。また、医療や介護のような無理な体勢で腰に力が加わるお仕事は尚更です。日中、姿勢に気をつけてお仕事すること同様、寝姿勢にも気をつけることが大切です。"},
          // {name: "妊婦さんに", icon: "https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/index%2FHome_ninpiu.png?alt=media&token=fbdcee53-32a2-4d86-97e5-5b0c8f836138", kind: "妊娠すると産道を通りやすくするため、靭帯を緩めるためのホルモンが分泌され骨盤周辺の関節が不安定になります。また、妊娠後期に赤ちゃんが成長すると体の重心が前に移動することにより、背中を反らせるような体勢をとって腰に負担がかかります。出産後に、楽しくおんぶや抱っこするためにも妊娠時のケアが大切です。妊娠中にはお腹を圧迫しにくいシムス体位もいいかもしれません。"},
          // {name: "集中力の向上に", icon: "https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/index%2FHome_shuchuryoku.png?alt=media&token=ff97b144-2a29-4c66-b4fa-f6151c0423d3", kind: "生真面目で働き者の日本人は国際的には最も睡眠時間が短い国の１つです。睡眠時間を延ばせないなら、睡眠の質をあげることで身体と心の回復に努めましょう。質の良い睡眠は集中力の向上や記憶の定着にもつながります。仕事や勉強の効率アップも期待できます。"}
          {name: "腰痛・肩こりに", icon: require("@/assets/Home_koshi.png"), kind: "立ち仕事や事務仕事など、１日中同じような姿勢でいることは腰痛などの原因となります。また、医療や介護のような無理な体勢で腰に力が加わるお仕事は尚更です。日中、姿勢に気をつけてお仕事すること同様、寝姿勢にも気をつけることが大切です。"},
          {name: "妊婦さんに", icon: require("@/assets/Home_ninpiu.png"), kind: "妊娠すると産道を通りやすくするため、靭帯を緩めるためのホルモンが分泌され骨盤周辺の関節が不安定になります。また、妊娠後期に赤ちゃんが成長すると体の重心が前に移動することにより、背中を反らせるような体勢をとって腰に負担がかかります。出産後に、楽しくおんぶや抱っこするためにも妊娠時のケアが大切です。妊娠中にはお腹を圧迫しにくいシムス体位もいいかもしれません。"},
          {name: "集中力の向上に", icon: require("@/assets/Home_shuchuryoku.png"), kind: "生真面目で働き者の日本人は国際的には最も睡眠時間が短い国の１つです。睡眠時間を延ばせないなら、睡眠の質をあげることで身体と心の回復に努めましょう。質の良い睡眠は集中力の向上や記憶の定着にもつながります。仕事や勉強の効率アップも期待できます。"}
        ],
        modalClass: ""
      }
    },
    // computed: {
    //   addTextLeft() {
    //     // let screenSize = document.body.offsetWidth
    //     if (screenSize <= 768) {
    //       return "has-text-left"
    //     }
    //   }
    // },
    mounted(){
      this.scrollTop();
      new Swiper(".swiper", {
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev',
        // },
        autoplay: {
          delay: 4000,
        },
      });
      new Swiper('.thumbnail', {
        centeredSlides:true,
        loop:true,
        slidesPerView: 5,
        slideToClickedSlide: true,
      });
    },
    methods: {
      pushPage(num) {
        this.$router.push(num);
      },
      scrollTop() {
        this.$nextTick(() => {
          window.scrollTo(0, 0);
        })
      },
    },
  }
</script>
